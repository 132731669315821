
@font-face {
  font-weight: 400; /* Соответствует значению normal */
  font-family: Almendra;
  src: url("./assets/fonts/Almendra-Regular.ttf");
}

@font-face {
  font-weight: 700; /* Соответствует значению bold */
  font-family: Almendra;
  src: url("./assets/fonts/Almendra-Bold.ttf");
}

@font-face {
  font-weight: 400; /* Соответствует значению normal */
  font-family: Roboto;
  src: url("./assets/fonts/Roboto-Regular.woff");
}

@font-face {
  font-weight: 400; /* Соответствует значению normal */
  font-family: Roboto;
  src: url("./assets/fonts/Roboto-Regular.woff");
}

@font-face {
  font-weight: 500; /* Соответствует значению medium */
  font-family: Roboto;
  src: url("./assets/fonts/Roboto-Medium.woff");
}

@font-face {
  font-weight: 600; /* Соответствует значению medium */
  font-family: Roboto;
  src: url("./assets/fonts/Roboto-Bold.woff");
}

@font-face {
  font-weight: 400; /* Соответствует значению normal */
  font-family: SuperClare;
  src: url("./assets/fonts/SuperclarendonRg.ttf");
}

@font-face {
  font-weight: 600; /* Соответствует значению bold */
  font-family: SuperClare;
  src: url("./assets/fonts/SuperclarendonBl.ttf");
}



html {
  scroll-behavior: smooth;
  font-size: 22px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: var(--black-color);
}

body {
  margin: 0;
  font-family: 'Roboto', 'Ubuntu', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -apple-system, BlinkMacSystemFont, 'Segoe UI',  */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.visually-hidden {
  display: none;
}

:root {
  /* colors */
  --main-background: linear-gradient(180deg, rgba(233, 205, 191, 0.8) 0%, rgb(224, 169, 127) 100%);
  --golden: #fbb270;
  --black-font: #252525;
  --dark-golden: #a17045;
  --dark-color: #3F3A3A;
  --light-brown-color: #EDCFB7;
  --dark-red-color: #9a3528;
  --white-color: rgb(238, 238, 238);
  --black-color: rgb(21, 21, 21);
  --golden-bright: #fcc10f;;
  --red: #850409;
  --light-gray: #ccc8c8;
  --dark-gray: #868686;
  --green: rgb(44, 138, 44);
  --green-light: rgb(80, 194, 80);
  --red-light: rgb(228, 48, 48);

  /* dimensions */
  --hand-card-width: 8rem;
  --cell-width: 7.6rem;
  --cell-content-width: 6.5rem;

}

.border-black {
  border: 0.2rem solid var(--black-color);
  border-radius: 0.5rem;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--dark-red-color);
  border: 2px ridge var(--golden);
}

